import { Component, OnInit } from "@angular/core"
import { EscalaGlobalForm } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { EscalaGlobalesSearchService } from "../escala-globales-search.service"

@Component({
    selector: "escala-globales-search",
    templateUrl: "./escala-globales-search.component.html",
    styleUrls: ["./escala-globales-search.component.scss"]
})
export class EscalaGlobalesSearchComponent implements OnInit {
    open: boolean
    subscription: Subscription
    escalaGlobal: any = {}
    escalaTipos: any = EscalaGlobalForm.formParams.tipo

    constructor(protected escalaGlobalSearchService: EscalaGlobalesSearchService) {}

    ngOnInit() {
        this.subscription = this.escalaGlobalSearchService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    search() {
        this.parseParams()
        this.escalaGlobalSearchService.search(this.escalaGlobal)
    }

    clear() {
        if (Object.keys(this.escalaGlobal).length) {
            this.escalaGlobal = {}
            this.escalaGlobalSearchService.search(this.escalaGlobal)
        }
    }

    parseParams() {
        for (const key of Object.keys(this.escalaGlobal)) {
            if (
                !this.escalaGlobal.hasOwnProperty(key) ||
                this.escalaGlobal[key] === undefined ||
                this.escalaGlobal[key] === ""
            ) {
                delete this.escalaGlobal[key]
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
