import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./crear-instrumento-multiple.component.html",
    styleUrls: ["./crear-instrumento-multiple.component.scss"]
})
export class CrearInstrumentoMultipleComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.crear_instrumento_multiple")
    }
}
