import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./horarios.component.html",
    styleUrls: ["./horarios.component.scss"]
})
export class HorariosComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit(): void {}
}
