import {
    EscalaGlobalesListComponent,
    EscalaGlobalesNewComponent,
    EscalaGlobalesEditComponent,
    EscalaGlobalesViewComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const escalaGlobalesRoutes: Routes = [
    {
        path: "escala_globales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EscalaGlobalesListComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "escala_globales/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EscalaGlobalesNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "escala_globales/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EscalaGlobalesViewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "escala_globales/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EscalaGlobalesEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const escalaGlobalesRouting = RouterModule.forChild(escalaGlobalesRoutes)
