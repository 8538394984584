import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Nivel, Niveles } from "@puntaje/achievements/api-services"

@Component({
    selector: "nivel-new",
    templateUrl: "./nivel-new.component.html",
    styleUrls: ["./nivel-new.component.scss"]
})
export class NivelNewComponent implements OnInit {
    nivel: Nivel

    constructor(protected nivelesService: Niveles, protected router: Router) {}

    ngOnInit() {
        this.nivel = new Nivel()
    }

    save(nivel: Nivel) {
        this.nivelesService.save(nivel).then(_ => {
            this.router.navigate(["/niveles"])
        })
    }
}
