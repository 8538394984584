import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { GrupoRecursosComponent } from "./grupo-recursos/grupo-recursos.component"
import { GrupoRecursosModule } from "@puntaje/puntaje/new-modules/grupo-recursos"
import { ToolNavbarModule } from "@puntaje/shared/core"
import { GrupoRecursosEditComponent } from "./grupo-recursos-edit/grupo-recursos-edit.component"
import { GrupoRecursosNewComponent } from "./grupo-recursos-new/grupo-recursos-new.component"
import { GrupoRecursoComponent } from "./grupo-recurso/grupo-recurso.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [
        GrupoRecursosComponent,
        GrupoRecursosEditComponent,
        GrupoRecursosNewComponent,
        GrupoRecursoComponent
    ],
    exports: [GrupoRecursosComponent, GrupoRecursosEditComponent, GrupoRecursosNewComponent, GrupoRecursoComponent],
    imports: [CommonModule, GrupoRecursosModule, ToolNavbarModule, CUILayoutsModule]
})
export class PuntajeGrupoRecursosModule {}
