import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./escala-globales-view.component.html",
    styleUrls: ["./escala-globales-view.component.scss"]
})
export class EscalaGlobalesViewComponent implements OnInit {
    escalaGlobalId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.escala_globales.show")
        this.route.params.subscribe(params => {
            this.escalaGlobalId = +params["id"]
        })
    }
}
