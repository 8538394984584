import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./escala-globales-edit.component.html",
    styleUrls: ["./escala-globales-edit.component.scss"]
})
export class EscalaGlobalesEditComponent implements OnInit {
    escalaGlobalId: number
    protected sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.escala_globales.edit")
        this.sub = this.route.params.subscribe(params => {
            this.escalaGlobalId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
