import { Component, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionMultiple,
    EvaluacionMultipleEvaluacion,
    EvaluacionMultiples,
    EvaluacionMultipleUsuario,
    EvaluacionTipos,
    InstrumentoMultiple,
    InstrumentoMultipleInstrumento,
    InstrumentoMultiples,
    EvaluacionTipo
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "crear-evaluacion-multiple",
    templateUrl: "./crear-evaluacion-multiple.component.html",
    styleUrls: ["./crear-evaluacion-multiple.component.scss"]
})
export class CrearEvaluacionMultipleComponent implements OnInit {
    evaluacionTipos: EvaluacionTipo[]
    generadorInstrumentos: GeneradorInstrumento[]
    @ViewChild("loadingLayoutPaso1") loadingLayoutPaso1: LoadingLayoutComponent
    @ViewChild("loadingLayoutPaso2", { static: true }) loadingLayoutPaso2: LoadingLayoutComponent

    evaluacionIds: number[]
    evaluacionMultipleNombre: string
    selectedEvaluacionTipo: EvaluacionTipo
    selectedGeneradorInstrumento: GeneradorInstrumento

    evaluaciones: Evaluacion[]

    evaluacionMultiple: EvaluacionMultiple
    savingEvaluacionMultiple: boolean = false

    constructor(
        protected evaluacionTiposService: EvaluacionTipos,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected evaluacionesService: Evaluaciones,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected instrumentoMultiplesService: InstrumentoMultiples,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.loadingLayoutPaso2.ready()

        const params = {
            evaluacion_tipo: {
                evaluacion_tipo: config.plataforma.evaluacionTiposMultiples
            }
        }

        this.evaluacionTiposService.where(params).then(evaluacionTipos => {
            this.evaluacionTipos = evaluacionTipos

            this.generadorInstrumentosService
                .where({
                    asignatura_plataforma: { plataforma_id: config.plataforma.id },
                    generador_instrumento: {
                        multiple: 1
                    },
                    with_asignatura: true,
                    with_tipo_instrumento: true,
                    visible: true
                })
                .then((generadores: GeneradorInstrumento[]) => {
                    this.generadorInstrumentos = generadores
                    this.loadingLayoutPaso1.ready()
                })
        })
    }

    onChangeEvaluacionIds(evaluacionIdsStr: string) {
        this.evaluacionIds = evaluacionIdsStr
            .split(/\s*,/)
            .map(i => +i)
            .filter(i => i)
    }

    getEvaluaciones() {
        const params = {
            evaluacion: {
                id: this.evaluacionIds
            },
            include: "evaluacion_tipo"
        }

        this.evaluacionesService.where(params).then(evaluaciones => {
            this.evaluaciones = this.evaluacionIds
                .map(evaluacionId => {
                    return evaluaciones.find(e => e.id == evaluacionId)
                })
                .filter(x => x)
        })
    }

    createMultiple() {
        this.savingEvaluacionMultiple = true

        const newInstrumentoMultiple = new InstrumentoMultiple()
        newInstrumentoMultiple.usuario_id = this.authService.getUserData().id
        newInstrumentoMultiple.generador_instrumento_id = this.selectedGeneradorInstrumento.id
        newInstrumentoMultiple.instrumento_multiple_instrumentos = this.evaluaciones.map(evaluacion => {
            const instrumentoMultipleInstrumento = new InstrumentoMultipleInstrumento()
            instrumentoMultipleInstrumento.instrumento_id = evaluacion.instrumento_id

            return instrumentoMultipleInstrumento
        })

        this.instrumentoMultiplesService.save(newInstrumentoMultiple).then(instrumentoMultiple => {
            const newEvaluacionMultiple = new EvaluacionMultiple()
            newEvaluacionMultiple.evaluacion_multiple = this.evaluacionMultipleNombre
            newEvaluacionMultiple.evaluacion_tipo_id = this.selectedEvaluacionTipo.id
            newEvaluacionMultiple.instrumento_multiple_id = instrumentoMultiple.id

            const evaluacionMultipleUsuarios = new EvaluacionMultipleUsuario()
            evaluacionMultipleUsuarios.usuario_id = this.authService.getUserData().id
            evaluacionMultipleUsuarios.receptor_type = "Usuario"

            newEvaluacionMultiple.evaluacion_multiple_usuarios = [evaluacionMultipleUsuarios]
            newEvaluacionMultiple.evaluacion_multiple_evaluaciones = this.evaluaciones.map(evaluacion => {
                const evaluacionMultipleEvaluacion = new EvaluacionMultipleEvaluacion()
                evaluacionMultipleEvaluacion.evaluacion_id = evaluacion.id

                return evaluacionMultipleEvaluacion
            })

            this.evaluacionMultiplesService.save(newEvaluacionMultiple).then(evaluacionMultiple => {
                this.evaluacionMultiple = evaluacionMultiple

                this.savingEvaluacionMultiple = false
            })
        })
    }
}
