import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "./grupo-recursos-new.component.html",
    styleUrls: ["./grupo-recursos-new.component.scss"]
})
export class GrupoRecursosNewComponent implements OnInit {
    grupoRecursoTipoCategorias: string[]

    subQuery: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.subQuery = this.route.queryParams.subscribe(queryParams => {
            this.grupoRecursoTipoCategorias =
                queryParams["grupo_recurso_tipo_categorias"] || this.grupoRecursoTipoCategorias
        })

        this.titleService.setTitle("titles.core.grupo_recursos.grupo_recursos.new")
    }

    ngOnDestroy(): void {
        this.subQuery.unsubscribe()
    }
}
