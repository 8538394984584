import { Component, Input, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { ToolNavbar } from "@puntaje/shared/core"

@Component({
    selector: "asignatura-evaluacion-tipos-toolnavbar",
    templateUrl: "./asignatura-evaluacion-tipos-toolnavbar.component.html",
    styleUrls: ["./asignatura-evaluacion-tipos-toolnavbar.component.scss"]
})
export class AsignaturaEvaluacionTiposToolnavbarComponent extends ToolNavbar implements OnInit {
    @Input() asignaturaEvaluacionTipo_id: number
    @Input() displayButtons: string
    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSearch: boolean = false
    displayToolNavbar: boolean = true

    urlAll: string
    urlNew: string
    urlEdit: string
    displayButtonsInput: string

    constructor(router: Router, route: ActivatedRoute) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/asignatura_evaluacion_tipos"
        this.urlNew = "/asignatura_evaluacion_tipos/new"
        if (this.asignaturaEvaluacionTipo_id != null) {
            this.urlEdit = "/asignatura_evaluacion_tipos/" + this.asignaturaEvaluacionTipo_id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit && !this.displayBtnSearch) {
            this.displayToolNavbar = false
        }
    }
}
