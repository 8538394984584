<loading-layout #loadingLayout>
    <table-with-actions
        *ngIf="niveles"
        [enableStickyHeaders]="true"
        [contentArray]="niveles"
        [showParams]="params"
        [tableButtonsArray]="tableButtonsArray"
        [disableTrackBy]="true"
    ></table-with-actions>
</loading-layout>
