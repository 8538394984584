import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ToolNavbar } from "@puntaje/shared/core"
import { EscalaGlobalesSearchService } from "../escala-globales-search.service"

@Component({
    selector: "escala-globales-toolnavbar",
    templateUrl: "./escala-globales-toolnavbar.component.html",
    styleUrls: ["./escala-globales-toolnavbar.component.scss"]
})
export class EscalaGlobalesToolnavbarComponent extends ToolNavbar implements OnInit {
    @Input() displayButtons: string = "search new"

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected escalaGlobalesSearchService: EscalaGlobalesSearchService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.resourceName = "escala_globales"
        this.displayButtonsInput = this.displayButtons
        super.ngOnInit()
    }

    openSearch() {
        this.escalaGlobalesSearchService.openSearch.next()
    }
}
