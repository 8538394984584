import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Nivel, NivelForm } from "@puntaje/achievements/api-services"

@Component({
    selector: "nivel-form",
    templateUrl: "./nivel-form.component.html",
    styleUrls: ["./nivel-form.component.scss"]
})
export class NivelFormComponent implements OnInit {
    @Input() nivel: Nivel
    oNivel: Nivel
    params = NivelForm.formParams
    form: UntypedFormGroup

    @Output() save = new EventEmitter<Nivel>()

    constructor(protected router: Router, protected cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["nivel"].currentValue != changes["nivel"].previousValue && this.nivel) {
            this.form = NivelForm.getForm(this.nivel)
            this.oNivel = this.nivel.clone()
        }
    }

    onSave() {
        NivelForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.save.emit(this.nivel)
        }
    }

    clear() {
        setTimeout(() => {
            this.nivel = this.oNivel.clone()
            NivelForm.markFormControlsAsPristine(this.form)
            NivelForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
