<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <form novalidate [formGroup]="form">
                <form-input
                    *ngFor="let key of params | keys"
                    [form]="form"
                    [params]="params[key]"
                    [(value)]="grupoRecursoTipo[key]"
                    [key]="key"
                ></form-input>
                <div class="form-group" *ngIf="grupoRecursoTipoCategorias && grupoRecursoTipoCategorias.length > 1">
                    <label>Categoría</label>

                    <select class="form-control" [(ngModel)]="grupoRecursoTipo.grupo_recurso_tipo_categoria_id">
                        <option *ngFor="let categoria of grupoRecursoTipoCategorias" [ngValue]="categoria.id">
                            {{ categoria.grupo_recurso_tipo_categoria }}
                        </option>
                    </select>
                </div>
                <button (click)="save()" type="button" class="btn btn-default">
                    {{ save_button_text | easyplaceholder: "Guardar" }}
                </button>
                <button (click)="clear()" type="button" class="btn btn-default">
                    {{ clear_button_text | easyplaceholder: "Borrar" }}
                </button>
            </form>
        </div>
    </div>
</loading-layout>
