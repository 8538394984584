import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, Injector } from "@angular/core"
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    AsignaturaEvaluacionTipo,
    AsignaturaEvaluacionTipoForm,
    AsignaturaEvaluacionTipos,
    AsignaturaMaterialClasificacionTipoForm,
    AsignaturaMaterialClasificacionTipo,
    AsignaturaMaterialNivelForm,
    AsignaturaMaterialNivel
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "asignatura-evaluacion-tipos-edit",
    templateUrl: "../asignatura-evaluacion-tipos-form/asignatura-evaluacion-tipos-form.component.html",
    styleUrls: ["./asignatura-evaluacion-tipos-edit.component.scss"]
})
export class AsignaturaEvaluacionTiposEditComponent implements OnInit {
    @Input() asignaturaEvaluacionTipoId: number
    oldAsignaturaEvaluacionTipo: AsignaturaEvaluacionTipo = new AsignaturaEvaluacionTipo()
    asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo = new AsignaturaEvaluacionTipo()
    asignaturaMaterialClasificacionTipos: AsignaturaMaterialClasificacionTipo[]
    params = AsignaturaEvaluacionTipoForm.formParams
    //Asignatura Material Clasificacion Tipos From
    paramsAsignaturaMaterialCt = AsignaturaMaterialClasificacionTipoForm.formParams
    asignaturaMaterialNivelesParams = AsignaturaMaterialNivelForm.formParams
    form: UntypedFormGroup
    asignaturaMaterialNivelesForm: UntypedFormArray
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Editar"
    clear_button_text = "Deshacer"
    nivelesToRemove = []

    constructor(
        protected asignaturaEvaluacionTiposServices: AsignaturaEvaluacionTipos,
        protected cdr: ChangeDetectorRef,
        protected router: Router,
        protected injector: Injector
    ) {}

    ngOnInit() {
        const params = {
            render_options: {
                include: ["asignatura_material_clasificacion_tipos", "asignatura_material_niveles"]
            }
        }
        this.asignaturaEvaluacionTiposServices
            .find(this.asignaturaEvaluacionTipoId, params)
            .then((asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo) => {
                this.oldAsignaturaEvaluacionTipo = asignaturaEvaluacionTipo
                this.asignaturaEvaluacionTipo = asignaturaEvaluacionTipo.clone()

                this.asignaturaMaterialClasificacionTipos =
                    asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos.length > 0
                        ? asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos
                        : [new AsignaturaMaterialClasificacionTipo()]

                this.form = AsignaturaEvaluacionTipoForm.getForm(this.asignaturaEvaluacionTipo)
                this.asignaturaMaterialNivelesForm = this.form.controls[
                    "asignatura_material_niveles"
                ] as UntypedFormArray

                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
    }

    save() {
        if (this.asignaturaEvaluacionTipo.asignatura_material_niveles) {
            const asignaturaMaterialClasificacionTipo = this.asignaturaMaterialClasificacionTipos[0]

            if (asignaturaMaterialClasificacionTipo.clasificacion_tipo && asignaturaMaterialClasificacionTipo.label) {
                this.asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos =
                    this.asignaturaMaterialClasificacionTipos
            } else if (
                !asignaturaMaterialClasificacionTipo.clasificacion_tipo &&
                !asignaturaMaterialClasificacionTipo.label &&
                asignaturaMaterialClasificacionTipo.id
            ) {
                Object.assign(asignaturaMaterialClasificacionTipo, { _destroy: true })
                this.asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos =
                    this.asignaturaMaterialClasificacionTipos
            }
            for (let index of this.nivelesToRemove) {
                let toRemove = new AsignaturaMaterialNivel()
                toRemove.id = index.id
                toRemove["_destroy"] = 1
                this.asignaturaEvaluacionTipo.asignatura_material_niveles.push(toRemove)
            }
        }
        AsignaturaEvaluacionTipoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.asignaturaEvaluacionTiposServices
                .update(this.asignaturaEvaluacionTipo.id, this.asignaturaEvaluacionTipo)
                .then(response => {
                    this.router.navigate(["asignatura_evaluacion_tipos"])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.asignaturaEvaluacionTipo = this.oldAsignaturaEvaluacionTipo.clone()
            AsignaturaEvaluacionTipoForm.markFormControlsAsPristine(this.form)
            AsignaturaEvaluacionTipoForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    showHideClasificaciones(event) {
        if (event) {
            this.asignaturaMaterialClasificacionTipos = [new AsignaturaMaterialClasificacionTipo()]
        } else {
            this.asignaturaMaterialClasificacionTipos = []
        }
    }

    agregarNivel() {
        this.asignaturaEvaluacionTipo.asignatura_material_niveles.push(new AsignaturaMaterialNivel())
    }

    removeAsignaturaNivel(index: number) {
        let control = this.asignaturaEvaluacionTipo.asignatura_material_niveles[index]
        if (control) {
            this.nivelesToRemove.push(control)
            this.asignaturaEvaluacionTipo.asignatura_material_niveles.splice(index, 1)
        }
    }
}
