import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Niveles } from "./niveles.service"

export class NivelView {
    public static get viewParams() {
        return {
            id: {
                label: "ID",
                type: LinkView,
                sorterEnabled: true,
                tableVisible: true,
                options: { class: Niveles, key: "id" }
            },
            nivel: { label: "Nombre", type: TextView, sorterEnabled: true, tableVisible: true },
            experiencia: { label: "Puntos", type: TextView, tableVisible: true },
            url_imagen: {
                label: "Recompensa",
                type: LinkView,
                tableVisible: true,
                options: { text: "Ver", withTargetBlank: true, key: "url_imagen" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
