import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Nivel, Niveles, NivelView } from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"

@Component({
    selector: "niveles",
    templateUrl: "./niveles.component.html",
    styleUrls: ["./niveles.component.scss"]
})
export class NivelesComponent implements OnInit {
    niveles: Nivel[]
    params = NivelView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected nivelesService: Niveles,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.getData()
    }

    getData() {
        this.loadingLayout.standby()
        this.nivelesService.where().then(niveles => {
            this.niveles = niveles
            this.loadingLayout.ready()
        })
    }

    view(nivel: Nivel) {
        this.router.navigate(["niveles/" + nivel.id])
    }

    edit(nivel: Nivel) {
        this.router.navigate(["niveles/" + nivel.id + "/edit"])
    }

    delete(id: number) {
        this.loadingLayout.standby()
        this.nivelesService.remove(id).then(response => {
            this.getData()
        })
    }

    showModalDelete(nivel: Nivel) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este instrumento predefinido?")
        this.simpleModalService.setModalCallback(() => this.delete(nivel.id))
    }
}
