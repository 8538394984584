import { Component, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import {
    Instrumento,
    InstrumentoMultiple,
    InstrumentoMultipleInstrumento,
    InstrumentoMultiples,
    Instrumentos
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "crear-instrumento-multiple",
    templateUrl: "./crear-instrumento-multiple.component.html",
    styleUrls: ["./crear-instrumento-multiple.component.scss"]
})
export class CrearInstrumentoMultipleComponent implements OnInit {
    generadorInstrumentos: GeneradorInstrumento[]
    @ViewChild("loadingLayoutPaso1") loadingLayoutPaso1: LoadingLayoutComponent
    @ViewChild("loadingLayoutPaso2", { static: true }) loadingLayoutPaso2: LoadingLayoutComponent

    instrumentoIds: number[]
    selectedGeneradorInstrumento: GeneradorInstrumento

    instrumentos: Instrumento[]
    instrumentoMultiple: InstrumentoMultiple
    savingInstrumentoMultiple: boolean = false

    constructor(
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected instrumentosService: Instrumentos,
        protected instrumentoMultiplesServices: InstrumentoMultiples,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.loadingLayoutPaso2.ready()

        this.generadorInstrumentosService
            .where({
                asignatura_plataforma: { plataforma_id: config.plataforma.id },
                generador_instrumento: {
                    multiple: 1
                },
                with_asignatura: true,
                with_tipo_instrumento: true
            })
            .then((generadores: GeneradorInstrumento[]) => {
                this.generadorInstrumentos = generadores
                this.loadingLayoutPaso1.ready()
            })
    }

    onChangeInstrumentoIds(instrumentoIdsStr: string) {
        this.instrumentoIds = instrumentoIdsStr
            .split(/\s*,/)
            .map(i => +i)
            .filter(i => i)
    }

    getInstrumentos() {
        this.loadingLayoutPaso2.standby()

        const params = {
            instrumento: {
                id: this.instrumentoIds
            }
        }

        this.instrumentosService.where(params).then(instrumentos => {
            this.instrumentos = instrumentos

            this.loadingLayoutPaso2.ready()
        })
    }

    createMultiple() {
        this.savingInstrumentoMultiple = true

        const newInstrumentoMultiple = new InstrumentoMultiple()
        newInstrumentoMultiple.generador_instrumento_id = this.selectedGeneradorInstrumento.id
        newInstrumentoMultiple.usuario_id = this.authService.getUserData().id

        newInstrumentoMultiple.instrumento_multiple_instrumentos = this.instrumentos.map(instrumento => {
            const instrumentoMultipleInstrumento = new InstrumentoMultipleInstrumento()
            instrumentoMultipleInstrumento.instrumento_id = instrumento.id

            return instrumentoMultipleInstrumento
        })

        this.instrumentoMultiplesServices.enableIgnoreCatch()

        this.instrumentoMultiplesServices
            .save(newInstrumentoMultiple)
            .then(instrumentoMultiple => {
                this.instrumentoMultiple = instrumentoMultiple

                this.savingInstrumentoMultiple = false
            })
            .catch(err => {
                this.savingInstrumentoMultiple = false
            })
    }
}
