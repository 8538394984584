import { Component, Input, Directive, Optional } from "@angular/core"
import { UntypedFormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { ReporteRazones, NebuAuthService } from "@puntaje/nebulosa/api-services"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Store } from "@ngrx/store"
import { State, GetAsignaturasConfig } from "@puntaje/puntaje/store"
import { LoginBaseComponent } from "./login_base/login_base.component"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { filter } from "rxjs/operators"
import { HttpClient } from "@angular/common/http"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "login-usuario",
    templateUrl: "login.component.html",
    styleUrls: ["login.component.scss"]
})
export class LoginComponent extends LoginBaseComponent {
    lookedLikeRut: boolean = false
    forgot_password_route: string
    constructor(
        formBuilder: UntypedFormBuilder,
        authService: AuthService,
        sessionService: SessionService,
        nebuAuthService: NebuAuthService,
        reporteRazonesService: ReporteRazones,
        usuariosService: Usuarios,
        simpleModalService: SimpleModalService,
        store: Store<State>,
        router: Router,
        @Optional() carrerasAuthService: CarrerasAuthService,
        loginService: Login,
        http: HttpClient,
        i18nService: I18nService
    ) {
        super(
            formBuilder,
            authService,
            sessionService,
            nebuAuthService,
            reporteRazonesService,
            usuariosService,
            simpleModalService,
            store,
            router,
            carrerasAuthService,
            loginService,
            http,
            i18nService
        )
    }

    ngOnInit() {
        this.form.controls.email.valueChanges.pipe(filter(x => !!x && x.length > 5)).subscribe((value: string) => {
            if (this.lookedLikeRut) value = value.replace(/-/g, "")
            if (this.looksLikeRut(value)) {
                this.lookedLikeRut = true
                const almostLast = value.length - 1
                value = value.substring(0, almostLast) + "-" + value.substring(almostLast)
                this.form.controls.email.setValue(value, { emitEvent: false })
            } else if (this.lookedLikeRut) {
                this.lookedLikeRut = false
                this.form.controls.email.setValue(value, { emitEvent: false })
            }
        })
    }

    public looksLikeRut(value: string) {
        let RUT_REGEXP = /^([0-9kK]{6,14})$/i
        let parts = RUT_REGEXP.exec(value)
        return parts
    }
}

@Directive({ selector: "content-under-login, content-left-login" })
export class LoginComponentTags {}
