import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core"
import { GrupoRecursoTipos, GrupoRecursoTipo, GrupoRecursoTipoView } from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "grupo-recursos",
    templateUrl: "./grupo-recursos.component.html",
    styleUrls: ["./grupo-recursos.component.scss"]
})
export class GrupoRecursosComponent implements OnInit {
    @Input() grupoRecursoTipoCategorias: string[]
    grupoRecursoTipos: GrupoRecursoTipo[]
    params = GrupoRecursoTipoView.viewParams
    tableButtonsArray: any

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected grupoRecursoTiposService: GrupoRecursoTipos,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            edit: {
                function: r =>
                    this.router.navigate(["/grupo_recursos", r.id, "edit"], {
                        queryParams: this.grupoRecursoTipoCategorias
                            ? { grupo_recurso_tipo_categorias: this.grupoRecursoTipoCategorias }
                            : {}
                    }),
                text: "Editar",
                glyphicon: "glyphicon-pencil"
            },
            delete: {
                function: this.delete,
                text: "Eliminar",
                glyphicon: "glyphicon-trash"
            },
            view: {
                function: r => this.router.navigate(["/grupo_recursos", r.id]),
                text: "Ver",
                glyphicon: "glyphicon-search"
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["grupoRecursoTipoCategorias"] && !changes["grupoRecursoTipoCategorias"].firstChange) {
            this.paginator.changePage(1)
        }
    }

    getData = (page: number, per: number) => {
        this.loadingLayout.standby()

        let params: any = {
            page,
            per
        }

        if (this.grupoRecursoTipoCategorias) {
            params = {
                ...params,
                grupo_recurso_tipo_categoria: {
                    grupo_recurso_tipo_categoria: this.grupoRecursoTipoCategorias
                }
            }
        }

        return this.grupoRecursoTiposService.where(params).then((grupoRecursoTipos, total) => {
            this.loadingLayout.ready()
            this.grupoRecursoTipos = grupoRecursoTipos

            return total
        })
    }

    delete = gr => {
        this.simpleModalService.cleanModal()
        this.simpleModalService.setModalHeader("Advertencia")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro de borrar esta categoría? Esta acción es irreversible."
        )
        this.simpleModalService.setModalCallback(() => {
            this.grupoRecursoTiposService.remove(gr.id).then(() => {
                this.paginator.reload()
            })
        })
        this.simpleModalService.showSimpleModal()
    }
}
