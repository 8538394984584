import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class HorarioForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            descripcion: {
                label: "Descripcion",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            image_file: {
                label: "Subir imagen (se completará 'imagen' automáticamente)",
                type: "file",
                visible: true,
                validations: []
            },
            url: {
                label: "Imagen (link de la imagen)",
                type: "text",
                visible: true,
                validations: []
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
