import { NivelesComponent, NivelComponent, NivelEditComponent, NivelNewComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

const routes: Routes = [
    {
        path: "niveles",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NivelesComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "niveles/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NivelNewComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "niveles/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NivelComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "niveles/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: NivelEditComponent, canActivate: [AuthGuard, TokenGuard] }]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminNivelesRoutingModule {}
