import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./horario.component.html",
    styleUrls: ["./horario.component.scss"]
})
export class HorarioComponent implements OnInit {
    sub: Subscription
    horarioId: number
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.horarios.show")
        this.sub = this.route.params.subscribe(params => {
            this.horarioId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
