import { ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { BaseForm } from "@puntaje/shared/core"

export class AsignaturaMaterialClasificacionTipoForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            asignatura_evaluacion_tipo_id: { label: "Asignatura Evaluacion Tipo", type: "hidden", visible: false },
            clasificacion_tipo: {
                label: "Clasificación Tipo",
                type: "select",
                visible: true,
                idField: "clasificacion_tipo",
                options: { class: ClasificacionTipos }
            },
            label: { label: "Label", type: "text", visible: true }
        }
    }
}
