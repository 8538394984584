import { PuntajeAsignaturaEvaluacionTiposModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { AsignaturaEvaluacionTiposModule } from "@puntaje/puntaje/new-modules/asignatura-evaluacion-tipos"
import { asignaturaEvaluacionTiposRouting } from "./admin-asignatura-evaluacion-tipos.routing"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { AdminLayoutsModule } from "../layouts"

@NgModule({
    declarations: [],
    imports: [
        asignaturaEvaluacionTiposRouting,
        CommonModule,
        CommonsLayoutsModule,
        AdminLayoutsModule,
        AsignaturaEvaluacionTiposModule,
        PuntajeAsignaturaEvaluacionTiposModule
    ]
})
export class AdminAsignaturaEvaluacionTiposModule {}
