import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ListaAsignaturaEvaluacionTiposComponent } from "./lista-asignatura-evaluacion-tipos/lista-asignatura-evaluacion-tipos.component"
import { AsignaturaEvaluacionTiposModule } from "@puntaje/puntaje/new-modules/asignatura-evaluacion-tipos"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { AsignaturaEvaluacionTiposNewComponent } from "./asignatura-evaluacion-tipos-new/asignatura-evaluacion-tipos-new.component"
import { AsignaturaEvaluacionTiposEditComponent } from "./asignatura-evaluacion-tipos-edit/asignatura-evaluacion-tipos-edit.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [
        ListaAsignaturaEvaluacionTiposComponent,
        AsignaturaEvaluacionTiposNewComponent,
        AsignaturaEvaluacionTiposEditComponent
    ],
    exports: [
        ListaAsignaturaEvaluacionTiposComponent,
        AsignaturaEvaluacionTiposNewComponent,
        AsignaturaEvaluacionTiposEditComponent
    ],
    imports: [CommonModule, CommonsLayoutsModule, AsignaturaEvaluacionTiposModule, CUILayoutsModule]
})
export class PuntajeAsignaturaEvaluacionTiposModule {}
