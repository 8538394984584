<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input type="text" name="id" class="form-control" [(ngModel)]="escalaGlobal.id" />
        </div>

        <div class="form-group">
            <label>Nombre</label>
            <input type="text" [(ngModel)]="escalaGlobal.nombre" name="nombre" class="form-control" />
        </div>

        <div class="form-group">
            <label>Tipos</label>
            <select class="form-control" [(ngModel)]="escalaGlobal.tipo" [ngModelOptions]="{ standalone: true }">
                <option [ngValue]="undefined">Todos</option>
                <option *ngFor="let escalaTipo of escalaTipos.options" [ngValue]="escalaTipo.id">
                    {{ escalaTipo.id }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" [value]="true" [(ngModel)]="escalaGlobal.activa" name="activo" />
                    Activo
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" [value]="false" [(ngModel)]="escalaGlobal.activa" name="activo" />
                    No activos
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" [(ngModel)]="escalaGlobal.activa" name="activo" />
                    Todos
                </label>
            </div>
        </div>
        <div>
            <cui-button-link class="btn_style" (click)="search()">Buscar</cui-button-link>
            <cui-button-link class="btn_style pull-right" (click)="clear()">Limpiar</cui-button-link>
        </div>
        <br />
    </div>
</form>
