import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from "@angular/core"
import { Horario, HorarioForm, Horarios } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { S3Service, S3 } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "horarios-edit",
    templateUrl: "./../horarios-form.component.html",
    styleUrls: ["./horarios-edit.component.scss"]
})
export class HorariosEditComponent implements OnInit {
    @Input() horarioId: number
    oHorario: Horario = new Horario()
    horario: Horario = new Horario()
    params = HorarioForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    constructor(
        protected router: Router,
        private s3Service: S3Service,
        protected cdr: ChangeDetectorRef,
        protected horariosService: Horarios
    ) {}

    ngOnInit() {
        this.form = HorarioForm.getForm(this.horario)
        this.horariosService.find(this.horarioId, {}).then((response: Horario) => {
            this.horario = response
            this.oHorario = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    // con nueva tabla
    save() {
        HorarioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            if (this.horario.image_file) {
                const promise = new Promise((resolve, reject) => {
                    this.s3Service.where(this.horario.getS3Params()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.horario.url = policy["key"]
                        this.s3Service.uploadToS3(
                            policy,
                            this.horario.image_file,
                            this.horariosService.tableName,
                            resolve
                        )
                    })
                })
                Promise.resolve(promise).then(res => {
                    this.doUpdate()
                })
            } else {
                this.doUpdate()
            }
        }
    }

    doUpdate = () => {
        this.horariosService.update(this.horario.id, this.horario).then(response => {
            this.router.navigate(["posts"])
        })
    }

    clear() {
        setTimeout(() => {
            this.horario = this.oHorario.clone()
            HorarioForm.markFormControlsAsPristine(this.form)
            HorarioForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
