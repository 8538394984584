import { PuntajeNivelesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { AdminNivelesRoutingModule } from "./admin-niveles-routing.module"

import { AdminLayoutsModule } from "../layouts"
import { NivelesModule } from "@puntaje/puntaje/new-modules/niveles"
import { ToolNavbarModule } from "@puntaje/shared/core"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AdminNivelesRoutingModule,
        AdminLayoutsModule,
        NivelesModule,
        ToolNavbarModule,
        PuntajeNivelesModule
    ]
})
export class AdminNivelesModule {}
