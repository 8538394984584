import { Component, Input, OnInit, ViewChild } from "@angular/core"
import {
    GrupoRecursoTipo,
    GrupoRecursoTiposForm,
    GrupoRecursoTipos,
    GrupoRecursoTipoCategoria,
    GrupoRecursoTipoCategorias
} from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"

@Component({
    selector: "grupo-recursos-new",
    templateUrl: "../grupo-recursos-form.component.html",
    styleUrls: ["./grupo-recursos-new.component.scss"]
})
export class GrupoRecursosNewComponent implements OnInit {
    @Input() grupoRecursoTipoCategoriasString: string[]
    grupoRecursoTipoCategorias: GrupoRecursoTipoCategoria[]
    grupoRecursoTipo: GrupoRecursoTipo = new GrupoRecursoTipo()
    params = GrupoRecursoTiposForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected grupoRecursoTiposService: GrupoRecursoTipos,
        protected router: Router,
        protected grupoRecursoTipoCategoriasService: GrupoRecursoTipoCategorias
    ) {}

    ngOnInit() {
        this.form = GrupoRecursoTiposForm.getForm(this.grupoRecursoTipo)
        if (this.grupoRecursoTipoCategoriasString) {
            this.grupoRecursoTipoCategoriasService
                .where({
                    grupo_recurso_tipo_categoria: {
                        grupo_recurso_tipo_categoria: this.grupoRecursoTipoCategoriasString
                    }
                })
                .then(grupoRecursoTipoCategorias => {
                    this.grupoRecursoTipoCategorias = grupoRecursoTipoCategorias
                    this.grupoRecursoTipo.grupo_recurso_tipo_categoria_id = grupoRecursoTipoCategorias[0]?.id

                    this.loadingLayout.ready()
                })
        } else {
            this.loadingLayout.ready()
        }
    }

    save() {
        GrupoRecursoTiposForm.markFormControlsAsTouched(this.form)

        if (this.form.valid) {
            this.grupoRecursoTiposService.save(this.grupoRecursoTipo).then(response => {
                const queryParams = {}
                if (this.grupoRecursoTipoCategoriasString) {
                    queryParams["grupo_recurso_tipo_categorias"] = this.grupoRecursoTipoCategoriasString
                }

                this.router.navigate(["grupo_recursos"], { queryParams })
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.grupoRecursoTipo = new GrupoRecursoTipo()
            GrupoRecursoTiposForm.markFormControlsAsPristine(this.form)
            GrupoRecursoTiposForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
