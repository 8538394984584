import { HorarioComponent, HorariosComponent, HorariosEditComponent, HorariosNewComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"

const routes: Routes = [
    { path: "horarios", component: LoggedLayoutComponent, children: [{ path: "", component: HorariosComponent }] },
    {
        path: "horarios/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HorariosNewComponent }]
    },
    { path: "horarios/:id", component: LoggedLayoutComponent, children: [{ path: "", component: HorarioComponent }] },
    {
        path: "horarios/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HorariosEditComponent }]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminHorariosRoutingModule {}
