<cui-logged-layout-sidebar [hideTitleOnPrint]="hideTitleOnPrint">
    <cui-layout-sidebar>
        <sidemenu class="clearfix" #sidemenu></sidemenu>
    </cui-layout-sidebar>
    <cui-layout-header>
        <navbar *ngIf="isLoggedIn()" (toggleSidemenuEvent)="sidemenu.toggleSidemenu()"></navbar>
    </cui-layout-header>
    <cui-layout-footer>
        <footer-component></footer-component>
    </cui-layout-footer>
</cui-logged-layout-sidebar>