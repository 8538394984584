import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { NivelesComponent } from "./niveles/niveles.component"
import { NivelesModule } from "@puntaje/puntaje/new-modules/niveles"
import { ToolNavbarModule } from "@puntaje/shared/core"
import { NivelNewComponent } from "./nivel-new/nivel-new.component"
import { NivelEditComponent } from "./nivel-edit/nivel-edit.component"
import { NivelComponent } from "./nivel/nivel.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [NivelesComponent, NivelNewComponent, NivelEditComponent, NivelComponent],
    exports: [NivelesComponent, NivelNewComponent, NivelEditComponent, NivelComponent],
    imports: [CommonModule, NivelesModule, ToolNavbarModule, CUILayoutsModule]
})
export class PuntajeNivelesModule {}
