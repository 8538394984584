import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Nivel, Niveles } from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "nivel-edit",
    templateUrl: "./nivel-edit.component.html",
    styleUrls: ["./nivel-edit.component.scss"]
})
export class NivelEditComponent implements OnInit {
    @Input() nivelId: number
    nivel: Nivel

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected nivelesService: Niveles, protected router: Router) {}

    ngOnInit() {
        this.loadingLayout.standby()

        this.nivelesService.find(this.nivelId).then(nivel => {
            this.nivel = nivel

            this.loadingLayout.ready()
        })
    }

    save(nivel: Nivel) {
        this.nivelesService.update(this.nivelId, nivel).then(_ => {
            this.router.navigate(["/niveles"])
        })
    }
}
