import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core"
import { Router } from "@angular/router"
import { S3, S3Service } from "@puntaje/shared/core"
//import { Horario, HorarioForm, Horarios } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Horario, HorarioForm, Horarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "horarios-new",
    templateUrl: "./../horarios-form.component.html",
    styleUrls: ["./horarios-new.component.scss"]
})
export class HorariosNewComponent implements OnInit {
    horario: Horario = new Horario()
    params = HorarioForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Crear Horario"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected router: Router,
        private s3Service: S3Service,
        protected cdr: ChangeDetectorRef,
        protected horariosService: Horarios
    ) {}

    ngOnInit() {
        this.form = HorarioForm.getForm(this.horario)
        this.loadingLayout.ready()
    }

    save() {
        HorarioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            if (this.horario.image_file) {
                const promise = new Promise((resolve, reject) => {
                    this.s3Service.where(this.horario.getS3Params()).then((policies: S3[]) => {
                        const policy = policies as any as S3
                        this.horario.url = policy["key"]
                        this.s3Service.uploadToS3(
                            policy,
                            this.horario.image_file,
                            this.horariosService.tableName,
                            resolve
                        )
                    })
                })
                Promise.resolve(promise).then(res => {
                    this.doSave()
                })
            } else {
                this.doSave()
            }
        }
    }

    doSave = () => {
        this.horariosService.save(this.horario).then(response => {
            this.router.navigate(["horarios"])
        })
    }

    clear() {
        setTimeout(() => {
            this.horario = new Horario()
            HorarioForm.markFormControlsAsPristine(this.form)
            HorarioForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
