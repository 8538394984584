import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { ayudasRouting } from "./admin-ayudas.routing"

import { AdminLayoutsModule } from "../layouts"
import { ToolNavbarModule } from "@puntaje/shared/core"

@NgModule({
    declarations: [],
    imports: [CommonModule, AdminLayoutsModule, ayudasRouting, ToolNavbarModule]
})
export class AdminAyudasModule {}
