import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    AsignaturaEvaluacionTipo,
    AsignaturaEvaluacionTipos,
    AsignaturaEvaluacionTipoForm,
    AsignaturaMaterialNivel,
    AsignaturaMaterialNivelForm,
    AsignaturaMaterialClasificacionTipo,
    AsignaturaMaterialClasificacionTipoForm
} from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "asignatura-evaluacion-tipos-new",
    templateUrl: "../asignatura-evaluacion-tipos-form/asignatura-evaluacion-tipos-form.component.html",
    styleUrls: ["./asignatura-evaluacion-tipos-new.component.scss"]
})
export class AsignaturaEvaluacionTiposNewComponent implements OnInit {
    asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo = new AsignaturaEvaluacionTipo()
    asignaturaMaterialClasificacionTipos: AsignaturaMaterialClasificacionTipo[]
    params = AsignaturaEvaluacionTipoForm.formParams
    //Asignatura Material Clasificacion Tipos From
    paramsAsignaturaMaterialCt = AsignaturaMaterialClasificacionTipoForm.formParams
    asignaturaMaterialNivelesParams = AsignaturaMaterialNivelForm.formParams
    form: UntypedFormGroup
    asignaturaMaterialNivelesForm: UntypedFormArray
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    nivelesToRemove = []
    config: typeof config

    constructor(protected asignaturaEvaluacionTiposServices: AsignaturaEvaluacionTipos, protected router: Router) {
        this.config = config
    }

    ngOnInit() {
        this.form = AsignaturaEvaluacionTipoForm.getForm(this.asignaturaEvaluacionTipo)

        this.asignaturaMaterialNivelesForm = this.form.controls["asignatura_material_niveles"] as UntypedFormArray
        this.asignaturaEvaluacionTipo.plataforma = config.plataforma.name
        this.loadingLayout.ready()
    }

    save() {
        if (this.asignaturaEvaluacionTipo.asignatura_material_niveles.length > 0) {
            const asignaturaMaterialClasificacionTipo = this.asignaturaMaterialClasificacionTipos[0]

            if (asignaturaMaterialClasificacionTipo.clasificacion_tipo && asignaturaMaterialClasificacionTipo.label) {
                this.asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos =
                    this.asignaturaMaterialClasificacionTipos
            }

            for (let index of this.nivelesToRemove) {
                let toRemove = new AsignaturaMaterialNivel()
                toRemove.id = index.id
                toRemove["_destroy"] = 1
                this.asignaturaEvaluacionTipo.asignatura_material_niveles.push(toRemove)
            }
        }
        AsignaturaEvaluacionTipoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.asignaturaEvaluacionTipo.plataforma = this.config.plataforma.name
            this.asignaturaEvaluacionTiposServices.save(this.asignaturaEvaluacionTipo).then(response => {
                this.router.navigate(["asignatura_evaluacion_tipos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.asignaturaEvaluacionTipo = new AsignaturaEvaluacionTipo()
            AsignaturaEvaluacionTipoForm.markFormControlsAsPristine(this.form)
            AsignaturaEvaluacionTipoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    showHideClasificaciones(event) {
        if (event) {
            this.asignaturaMaterialClasificacionTipos = [new AsignaturaMaterialClasificacionTipo()]
        } else {
            this.asignaturaMaterialClasificacionTipos = []
        }
    }

    agregarNivel() {
        this.asignaturaEvaluacionTipo.asignatura_material_niveles.push(new AsignaturaMaterialNivel())
    }

    removeAsignaturaNivel(index: number) {
        let control = this.asignaturaEvaluacionTipo.asignatura_material_niveles[index]
        if (control) {
            this.nivelesToRemove.push(control)
            this.asignaturaEvaluacionTipo.asignatura_material_niveles.splice(index, 1)
        }
    }
}
