import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { UsuarioPerfilAdminComponent, UsuariosEditComponent } from "@puntaje/puntaje/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const routes: Routes = [
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuarioPerfilAdminComponent, canActivate: [AuthGuard], data: { tab: "" } }]
    }
]

export const routing = RouterModule.forChild(routes)
