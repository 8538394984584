<ll-titulo>Categorías</ll-titulo>
<ll-toolnavbar>
    <toolnavbar
        resourceName="grupo_recursos"
        displayButtonsInput="new"
        [queryParams]="grupoRecursoTipoCategorias ? { grupo_recurso_tipo_categorias: grupoRecursoTipoCategorias } : {}"
    ></toolnavbar>
</ll-toolnavbar>

<grupo-recursos [grupoRecursoTipoCategorias]="grupoRecursoTipoCategorias"></grupo-recursos>
