import { AppConfig, BaseForm } from "@puntaje/shared/core"
import { Asignaturas, Clasificaciones, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { EvaluacionTipos } from "./evaluacion_tipos.service"
import { AsignaturaMaterialClasificacionTipoForm } from "./asignatura_material_clasificacion_tipos.form"
import { AsignaturaMaterialNivelForm } from "./asignatura_material_niveles.form"

declare const config: AppConfig

export class AsignaturaEvaluacionTipoForm extends BaseForm {
    public static get formParams(): any {
        var clases = [
            "matematica",
            "lenguaje",
            "historia",
            "ciencias",
            "fisica",
            "quimica",
            "biologia",
            "ingles",
            "matematicas",
            "lectura_critica",
            "competencias_ciudadanas",
            "ciencias_naturales"
        ]
        var iconos = ["matematica-4", "lenguaje-4", "historia-4", "ciencias-4", "fisica-4", "quimica-5"]
        const cursoTipos = Object.values(config.evaluaciones)
            .map(evaluacion => evaluacion?.cursoTipo)
            .filter(e => e)

        function isIcono(isIcono) {
            let data = []
            if (isIcono) {
                clases.concat(iconos).forEach(icono => {
                    data.push(getObjet(icono))
                })
            } else {
                clases.forEach(clase => {
                    data.push(getObjet(clase))
                })
            }

            return data
        }

        function getObjet(item) {
            return {
                id: item,
                toString: function () {
                    return item
                }
            }
        }

        return {
            id: { label: "Id", type: "hidden", visible: false },
            asignatura_id: { label: "Asignatura", type: "select", visible: true, options: { class: Asignaturas } },
            evaluacion_tipo_id: {
                label: "Evaluacion tipo",
                type: "select",
                visible: true,
                options: { class: EvaluacionTipos }
            },
            abreviacion: { label: "abreviación", type: "text", visible: true },
            asignatura_plataforma: {
                label: "Asignatura Plataforma (nombre que se muestra en algunos lados)",
                type: "text",
                visible: true
            },
            clase: {
                label: "Clase",
                type: "select",
                visible: true,
                options: isIcono(false)
            },
            icono: {
                label: "Icono",
                type: "radio",
                tableVisible: true,
                icono: true,
                options: isIcono(true)
            },
            material_visible: { label: "Contenido (sección: biblioteca)", type: "checkbox", tableVisible: true },
            solo_contenido: {
                label: "Solo tiene Contenido (no tiene ítems, por ende no hay que mostrar historial, estadísticas, etc)",
                type: "checkbox",
                tableVisible: true
            },
            with_items: { label: "Con items (secciones: generar prueba)", type: "checkbox", tableVisible: true },
            with_evaluaciones_recomendadas: {
                label: "Con evaluaciones recomendadas (sección: evaluación recomendada)",
                type: "checkbox",
                tableVisible: true
            },
            with_pruebas_propias: {
                label: "Con pruebas propias (sección: pruebas propias)",
                type: "checkbox",
                tableVisible: true
            },
            with_buscador_en_generador: {
                label: "Con buscador en generador (sección: generar prueba profesor)",
                type: "checkbox",
                tableVisible: true
            },
            with_reforzamientos: {
                label: "Con reforzamientos (sección: tabla de resultados en estadísticas)",
                type: "checkbox",
                tableVisible: true
            },
            with_planes_de_clase: {
                label: "Con planes de clase (sección: planes de clase)",
                type: "checkbox",
                tableVisible: true
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false },
            asignatura_material_clasificacion_tipos: {
                type: "model",
                class: AsignaturaMaterialClasificacionTipoForm,
                hidden: false
            },
            asignatura_material_niveles: { type: "models", class: AsignaturaMaterialNivelForm, hidden: false }
        }
    }
}
