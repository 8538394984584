<ng-template [ngIf]="isLoggedIn()">
    <div class="ingresa">
        <h3>Ingresar</h3>
        <div class="clearfix">
            <cui-button (click)="logout()" *ngIf="isLoggedIn()" class="pull-right btn_style">Cerrar sesión</cui-button>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="!isLoggedIn()">
    <div class="ingresa">
        <form novalidate [formGroup]="form" (ngSubmit)="login()">
            <h3>Ingresar</h3>
            <div class="mensaje-login" *ngIf="loginMessage">
                <p>
                    Eres usuario con cuenta en SENCE, te invitamos a ingresar a la plataforma desde
                    <a href="https://sicciudadano.sence.cl/Autentificacion/LoginPersona">SENCE</a>
                    .
                </p>
            </div>
            <div class="mensaje-error" *ngIf="formHasErrors()">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
                <div *ngIf="checkEmail()">
                    <p>{{ config.mensajesLogin.invalidLoginId }}</p>
                </div>
                <div *ngIf="checkPassword()"><p>Debes incluir una contraseña.</p></div>
            </div>
            <div class="doscampos">
                <div class="form-group">
                    <input
                        type="text"
                        name="email"
                        [(ngModel)]="email"
                        [placeholder]="config.mensajesLogin.idPlaceholder"
                        *ngIf="!isLoggedIn()"
                        class="form-control"
                        formControlName="email"
                    />
                </div>
                <div class="form-group">
                    <input
                        type="password"
                        name="password"
                        [(ngModel)]="password"
                        placeholder="Contraseña"
                        *ngIf="!isLoggedIn()"
                        class="form-control"
                        formControlName="password"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="checkbox" *ngIf="enableRememberMe">
                        <label>
                            <input type="checkbox" />
                            Recordarme
                        </label>
                    </div>
                    <div class="content-left-login" #contentLeftLogin>
                        <ng-content select="content-left-login"></ng-content>
                    </div>
                    <ng-container *ngIf="enableRegister">
                        <cui-button
                            [isBlock]="true"
                            type="button"
                            class="btn-ingresa btn-registro"
                            (click)="goToRegister()"
                        >
                            Regístrate aquí
                        </cui-button>
                    </ng-container>
                </div>
                <div class="col-md-6">
                    <cui-button type="submit" [isBlock]="true" class="btn-ingresa" *ngIf="!isLoggedIn()">
                        Ingresar
                    </cui-button>
                </div>
            </div>
            <div class="pwd-reset" *ngIf="enablePasswordRecovery">
                <p>
                    ¿Olvidaste tu contraseña?
                    <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">Haz click aquí.</a>
                </p>
            </div>

            <div class="contact-us-container clearfix" *ngIf="enableContactUs">
                <div class="eres-nuevo-text">¿Quieres registrarte?</div>
                <div class="contact-us-button-container clearfix">
                    <a
                        class="btn-ingresa btn"
                        href="{{ contactUsDomId }}"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        ¡Contáctanos!
                    </a>
                </div>
            </div>

            <div class="facebook-login" *ngIf="enableFacebookLogin">
                <facebook-button></facebook-button>
            </div>
            <div class="google-login" *ngIf="enableGoogleLogin">
                <google-button></google-button>
            </div>
            <div class="content-under-login" #contentUnderLogin>
                <ng-content select="content-under-login"></ng-content>
            </div>
        </form>
    </div>
</ng-template>
<request-reset-password></request-reset-password>
