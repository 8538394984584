import { PuntajeHorariosModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
//Own
import { AdminLayoutsModule } from "../layouts"
import { ToolNavbarModule } from "@puntaje/shared/core"
import { HorariosModule } from "@puntaje/puntaje/new-modules/horarios"
import { AdminHorariosRoutingModule } from "./admin-horarios-routing.module"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AdminLayoutsModule,
        AdminHorariosRoutingModule,
        ToolNavbarModule,
        HorariosModule,
        PuntajeHorariosModule
    ]
})
export class AdminHorariosModule {}
