import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import {
    GrupoRecursoComponent,
    GrupoRecursosComponent,
    GrupoRecursosEditComponent,
    GrupoRecursosNewComponent
} from "@puntaje/puntaje/core"

const routes: Routes = [
    {
        path: "grupo_recursos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GrupoRecursosComponent }]
    },
    {
        path: "grupo_recursos/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GrupoRecursosNewComponent }]
    },
    {
        path: "grupo_recursos/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GrupoRecursoComponent }]
    },
    {
        path: "grupo_recursos/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GrupoRecursosEditComponent }]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminGrupoRecursosRoutingModule {}
