import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-asignatura-evaluacion-tipos-new",
    templateUrl: "./asignatura-evaluacion-tipos-new.component.html",
    styleUrls: ["./asignatura-evaluacion-tipos-new.component.scss"]
})
export class AsignaturaEvaluacionTiposNewComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.asignatura_evaluacion_tipos.new")
    }
}
