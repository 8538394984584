<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <ng-container *ngFor="let key of params | keys">
            <model-simple-view
                *ngIf="!params[key]['showHidden']"
                [params]="params[key]"
                [value]="nivel"
                [key]="key"
            ></model-simple-view>
        </ng-container>
    </div>
</loading-layout>
