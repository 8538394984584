import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"

import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { InstrumentoPredefinidosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { EvaluacionesMasivasComponent } from "./evaluaciones_masivas.component"
import { EvaluacionAdminComponent } from "./evaluacion-admin.component"

import { UtilModule } from "@puntaje/shared/core"
import { CrearEvaluacionRecomendadaComponent } from "./evaluaciones-recomendadas/crear-evaluacion-recomendada/crear-evaluacion-recomendada.component"
import { ListaEvaluacionesRecomendadasComponent } from "./evaluaciones-recomendadas/lista-evaluaciones-recomendadas/lista-evaluaciones-recomendadas.component"
import { EditarEvaluacionRecomendadaComponent } from "./evaluaciones-recomendadas/editar-evaluacion-recomendada/editar-evaluacion-recomendada.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { CrearEvaluacionMultipleComponent } from "./crear-evaluacion-multiple.component"
import { EstadisticasEvaluacionRecomendadaComponent } from "./estadisticas-evaluacion-recomendada/estadisticas-evaluacion-recomendada.component"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        InstrumentosModule,
        InstrumentoPredefinidosModule,
        EnsayosModule,
        EstadisticasModule,
        UtilModule,
        CUITextsModule,
        CUIInputsModule,
        CUILayoutsModule
    ],
    exports: [
        EvaluacionesMasivasComponent,
        EvaluacionAdminComponent,
        CrearEvaluacionRecomendadaComponent,
        ListaEvaluacionesRecomendadasComponent,
        EditarEvaluacionRecomendadaComponent,
        CrearEvaluacionMultipleComponent,
        EstadisticasEvaluacionRecomendadaComponent
    ],
    declarations: [
        EvaluacionesMasivasComponent,
        EvaluacionAdminComponent,
        CrearEvaluacionRecomendadaComponent,
        ListaEvaluacionesRecomendadasComponent,
        EditarEvaluacionRecomendadaComponent,
        CrearEvaluacionMultipleComponent,
        EstadisticasEvaluacionRecomendadaComponent
    ]
})
export class PuntajeEvaluacionesModule {}
