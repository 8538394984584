import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { EscalaGlobal, EscalaGlobalAliases, EscalaGlobales, EscalaGlobalView } from "@puntaje/puntaje/api-services"
import { EscalaGlobalesSearchService } from "../escala-globales-search.service"

@Component({
    selector: "escala-globales-list",
    templateUrl: "./escala-globales-list.component.html",
    styleUrls: ["./escala-globales-list.component.scss"]
})
export class EscalaGlobalesListComponent implements OnInit {
    params: any
    tableButtonsArray: any
    escalaGlobales: EscalaGlobal[]

    @ViewChild("loadingLayout", { static: true })
    loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent)
    paginator: PaginatorComponent

    searchSub: Subscription
    searchParams: any

    constructor(
        protected escalaGlobalService: EscalaGlobales,
        protected escalaGlobalAliasService: EscalaGlobalAliases,
        protected simpleModalService: SimpleModalService,
        protected escalaGlobalSearchService: EscalaGlobalesSearchService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.escalaGlobales = []
        this.params = EscalaGlobalView.viewParams
        this.searchParams = {}

        this.tableButtonsArray = {
            view: {
                function: this.view.bind(this),
                text: "Ver",
                glyphicon: "glyphicon-search"
            },
            activate: {
                function: this.activate.bind(this),
                text: "Activar",
                glyphicon: "glyphicon-pencil",
                showIf: escalaGlobal => !escalaGlobal.activa
            },
            deactivate: {
                function: this.deactivate.bind(this),
                text: "Desactivar",
                glyphicon: "glyphicon-pencil",
                showIf: escalaGlobal => escalaGlobal.activa
            },
            edit: {
                function: this.edit.bind(this),
                text: "Editar",
                glyphicon: "glyphicon-pencil"
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            },
            clone: {
                function: this.showModalClone.bind(this),
                text: "Clonar",
                glyphicon: "glyphicon-pencil",
                callModal: true
            }
        }

        this.searchSub = this.escalaGlobalSearchService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number = 1, count: number = 20) => {
        const params = {
            page,
            per: count,
            escala_global: this.searchParams
        }
        this.loadingLayout.standby()

        return this.escalaGlobalService.where(params).then((response: EscalaGlobal[], total: number) => {
            this.escalaGlobales = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(escalaGlobal: EscalaGlobal) {
        this.router.navigate(["escala_globales/" + escalaGlobal.id])
    }

    edit(escalaGlobal: EscalaGlobal) {
        this.router.navigate(["escala_globales/" + escalaGlobal.id + "/edit"])
    }

    activate(escalaGlobal: EscalaGlobal) {
        this.loadingLayout.standby()
        escalaGlobal.activa = true

        this.escalaGlobalService.update(escalaGlobal.id, escalaGlobal).then(() => {
            this.paginator.reload()
        })
    }

    deactivate(escalaGlobal: EscalaGlobal) {
        this.loadingLayout.standby()
        escalaGlobal.activa = false

        this.escalaGlobalService.update(escalaGlobal.id, escalaGlobal).then(() => {
            this.paginator.reload()
        })
    }

    showModalDelete(escalaGlobal: EscalaGlobal) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea eliminar la escala " + escalaGlobal.nombre + "?"
        )
        this.simpleModalService.setModalCallback(() => this.delete(escalaGlobal.id))
    }

    delete(id: number) {
        this.loadingLayout.standby()
        this.escalaGlobalService.remove(id).then(() => {
            this.paginator.reload()
        })
    }

    showModalClone(escalaGlobal: EscalaGlobal) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea clonar esta escala?")
        this.simpleModalService.setModalCallback(() => this.clone(escalaGlobal))
    }

    clone(escalaGlobal: EscalaGlobal) {
        let clonEG = escalaGlobal.clone()
        clonEG.nombre = clonEG.nombre + " copia"
        this.escalaGlobalAliasService
            .where({ escala_global_alias: { escala_global_id: clonEG.id } })
            .then(escalaGlobalAliases => {
                escalaGlobalAliases.forEach(ega => {
                    ega.escala_global_id = null
                    ega.id = null
                })
                clonEG.escala_global_aliases = escalaGlobalAliases
                clonEG.id = null
                this.escalaGlobalService.save(clonEG).then(clon => {
                    this.router.navigate(["escala_globales/" + clon.id])
                })
            })
    }
}
