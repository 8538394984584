import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ToolNavbarModule } from "@puntaje/shared/core"
import { HorariosModule } from "@puntaje/puntaje/new-modules/horarios"
import { HorariosNewComponent } from "./horarios-new/horarios-new.component"
import { HorariosEditComponent } from "./horarios-edit/horarios-edit.component"
import { HorarioComponent } from "./horario/horario.component"
import { HorariosComponent } from "./horarios/horarios.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [HorariosNewComponent, HorariosEditComponent, HorarioComponent, HorariosComponent],
    exports: [HorariosNewComponent, HorariosEditComponent, HorarioComponent, HorariosComponent],
    imports: [CommonModule, ToolNavbarModule, HorariosModule, CUILayoutsModule]
})
export class PuntajeHorariosModule {}
