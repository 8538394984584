import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { AdminGrupoRecursosRoutingModule } from "./admin-grupo-recursos-routing.module"
import { AdminLayoutsModule } from "../layouts"
import { GrupoRecursosModule } from "@puntaje/puntaje/new-modules/grupo-recursos"
import { ToolNavbarModule } from "@puntaje/shared/core"
import { PuntajeGrupoRecursosModule } from "@puntaje/puntaje/core"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AdminLayoutsModule,
        GrupoRecursosModule,
        AdminGrupoRecursosRoutingModule,
        ToolNavbarModule,
        PuntajeGrupoRecursosModule
    ]
})
export class AdminGrupoRecursosModule {}
