import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { GrupoRecursosComponent } from "./grupo-recursos/grupo-recursos.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { PaginatorModule, FormModule, ModelViewModule, UtilModule } from "@puntaje/shared/core"
import { GrupoRecursosEditComponent } from "./grupo-recursos-edit/grupo-recursos-edit.component"
import { GrupoRecursosNewComponent } from "./grupo-recursos-new/grupo-recursos-new.component"
import { GrupoRecursoComponent } from "./grupo-recurso/grupo-recurso.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { SortableModule } from "ngx-bootstrap"
import { CUIInputsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [
        GrupoRecursosComponent,
        GrupoRecursosEditComponent,
        GrupoRecursosNewComponent,
        GrupoRecursoComponent
    ],
    imports: [
        CommonModule,
        CommonsLayoutsModule,
        FormsModule,
        PaginatorModule,
        ReactiveFormsModule,
        FormModule,
        UtilModule,
        ModelViewModule,
        SortableModule,
        CUIInputsModule
    ],
    exports: [GrupoRecursosComponent, GrupoRecursosNewComponent, GrupoRecursosEditComponent, GrupoRecursoComponent]
})
export class GrupoRecursosModule {}
