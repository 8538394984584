import { Component, HostBinding } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Menú a la izquierda del sitio. En este momento es el menú de puntaje.

#################################*/

@Component({
    templateUrl: "sidemenu.component.html",
    selector: "sidemenu",
    styleUrls: ["sidemenu.component.scss"]
})
export class SidemenuComponent {
    config: typeof config = config

    @HostBinding("class.show-sidemenu") showSidemenu: boolean = false

    toggleSidemenu() {
        this.showSidemenu = !this.showSidemenu
    }

    closeSidemenu() {
        this.showSidemenu = false
    }
}
