<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <div *ngIf="form">
                <form novalidate [formGroup]="form">
                    <form-input
                        [form]="form"
                        [params]="params.asignatura_id"
                        [(value)]="asignaturaEvaluacionTipo['asignatura_id']"
                        [key]="'asignatura_id'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.evaluacion_tipo_id"
                        [(value)]="asignaturaEvaluacionTipo['evaluacion_tipo_id']"
                        [key]="'evaluacion_tipo_id'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.abreviacion"
                        [(value)]="asignaturaEvaluacionTipo['abreviacion']"
                        [key]="'abreviacion'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.asignatura_plataforma"
                        [(value)]="asignaturaEvaluacionTipo['asignatura_plataforma']"
                        [key]="'asignatura_plataforma'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.clase"
                        [(value)]="asignaturaEvaluacionTipo['clase']"
                        [key]="'clase'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.icono"
                        [(value)]="asignaturaEvaluacionTipo['icono']"
                        [key]="'icono'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.material_visible"
                        [(value)]="asignaturaEvaluacionTipo['material_visible']"
                        [key]="'material_visible'"
                        (valueChange)="showHideClasificaciones($event)"
                    ></form-input>
                    <ng-container #name *ngIf="asignaturaEvaluacionTipo['material_visible']">
                        <form-input
                            [form]="form"
                            [params]="paramsAsignaturaMaterialCt.clasificacion_tipo"
                            [(value)]="asignaturaMaterialClasificacionTipos[0]['clasificacion_tipo']"
                            [key]="'clasificacion_tipo'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="paramsAsignaturaMaterialCt.label"
                            [(value)]="asignaturaMaterialClasificacionTipos[0]['label']"
                            [key]="'label'"
                        ></form-input>
                        <button (click)="agregarNivel()" type="button" class="btn btn-default">Agregar Nivel</button>
                        <div
                            *ngFor="
                                let asignaturaNivel of asignaturaEvaluacionTipo['asignatura_material_niveles'];
                                let i = index
                            "
                        >
                            <form-input
                                [form]="asignaturaMaterialNivelesForm[i]"
                                [params]="asignaturaMaterialNivelesParams.clasificacion"
                                [(value)]="asignaturaEvaluacionTipo['asignatura_material_niveles'][i]['clasificacion']"
                                [key]="'clasificacion'"
                            ></form-input>
                            <button
                                type="button"
                                class="btn btn-xs btn-default remove-btn"
                                (click)="removeAsignaturaNivel(i)"
                            >
                                Eliminar
                            </button>
                        </div>
                    </ng-container>
                    <form-input
                        [form]="form"
                        [params]="params.solo_contenido"
                        [(value)]="asignaturaEvaluacionTipo['solo_contenido']"
                        [key]="'solo_contenido'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.with_items"
                        [(value)]="asignaturaEvaluacionTipo['with_items']"
                        [key]="'with_items'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.with_evaluaciones_recomendadas"
                        [(value)]="asignaturaEvaluacionTipo['with_evaluaciones_recomendadas']"
                        [key]="'with_evaluaciones_recomendadas'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.with_reforzamientos"
                        [(value)]="asignaturaEvaluacionTipo['with_reforzamientos']"
                        [key]="'with_reforzamientos'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.with_pruebas_propias"
                        [(value)]="asignaturaEvaluacionTipo['with_pruebas_propias']"
                        [key]="'with_pruebas_propias'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.with_buscador_en_generador"
                        [(value)]="asignaturaEvaluacionTipo['with_buscador_en_generador']"
                        [key]="'with_buscador_en_generador'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params.with_planes_de_clase"
                        [(value)]="asignaturaEvaluacionTipo['with_planes_de_clase']"
                        [key]="'with_planes_de_clase'"
                    ></form-input>

                    <label>
                        * Las secciones de resultados y pruebas propias se muestran siempre excepto si está marcado
                        "solo tiene contenido".
                    </label>
                    <br />
                    <button (click)="save()" type="button" class="btn btn-default">{{ save_button_text }}</button>
                    <button (click)="clear()" type="button" class="btn btn-default">Borrar</button>
                </form>
            </div>
        </div>
    </div>
</loading-layout>
