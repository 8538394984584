import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from "@angular/core"
import {
    GrupoRecursoTipo,
    GrupoRecursoTiposForm,
    GrupoRecursoTipos,
    GrupoRecursoTipoCategoria
} from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"

@Component({
    selector: "grupo-recursos-edit",
    templateUrl: "../grupo-recursos-form.component.html",
    styleUrls: ["./grupo-recursos-edit.component.scss"]
})
export class GrupoRecursosEditComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() grupoRecursoTipoId: number
    @Input() grupoRecursoTipoCategoriasString: string[]
    grupoRecursoTipoCategorias: GrupoRecursoTipoCategoria[]

    oldGrupoRecursoTipo: GrupoRecursoTipo = new GrupoRecursoTipo()
    grupoRecursoTipo: GrupoRecursoTipo = new GrupoRecursoTipo()
    params = GrupoRecursoTiposForm.formParams
    form: UntypedFormGroup

    save_button_text = "Editar"
    clear_button_text = "Deshacer"

    constructor(
        protected grupoRecursoTiposService: GrupoRecursoTipos,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = GrupoRecursoTiposForm.getForm(this.grupoRecursoTipo)
        this.grupoRecursoTiposService.find(this.grupoRecursoTipoId).then((response: GrupoRecursoTipo) => {
            this.grupoRecursoTipo = response

            this.oldGrupoRecursoTipo = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        GrupoRecursoTiposForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.grupoRecursoTiposService.update(this.grupoRecursoTipo.id, this.grupoRecursoTipo).then(response => {
                const queryParams = {}
                if (this.grupoRecursoTipoCategoriasString) {
                    queryParams["grupo_recurso_tipo_categorias"] = this.grupoRecursoTipoCategoriasString
                }

                this.router.navigate(["grupo_recursos"], { queryParams })
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.grupoRecursoTipo = this.oldGrupoRecursoTipo.clone()
            GrupoRecursoTiposForm.markFormControlsAsPristine(this.form)
            GrupoRecursoTiposForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
