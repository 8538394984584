import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Nivel, Niveles, NivelView } from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "nivel",
    templateUrl: "./nivel.component.html",
    styleUrls: ["./nivel.component.scss"]
})
export class NivelComponent implements OnInit {
    @Input() nivelId: number

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    nivel: Nivel
    params = NivelView.viewParams

    constructor(protected nivelesService: Niveles) {}

    ngOnInit() {
        this.loadingLayout.standby()

        this.nivelesService.find(this.nivelId).then(nivel => {
            this.nivel = nivel

            this.loadingLayout.ready()
        })
    }
}
