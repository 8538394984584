import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AppConfig, BaseForm } from "@puntaje/shared/core"

declare const config: AppConfig
export class AsignaturaMaterialNivelForm extends BaseForm {
    public static get formParams() {
        const cursoTipos = Object.values(config.evaluaciones)
            .map(evaluacion => evaluacion?.cursoTipo)
            .filter(e => e)
        return {
            id: { label: "Id", type: "hidden", visible: false },
            asignatura_evaluacion_tipo_id: { label: "Asignatura Evaluacion Tipo", type: "hidden", visible: false },
            clasificacion: {
                label: "Clasificación Niveles",
                type: "select",
                options: {
                    class: Clasificaciones,
                    params: { clasificacion_tipo: { clasificacion_tipo: cursoTipos } }
                },
                idField: "clasificacion",
                visible: true
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false }
        }
    }
}
