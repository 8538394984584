import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./crear-evaluacion-multiple.component.html"
})
export class CrearEvaluacionMultipleComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.evaluaciones.crear_evaluacion_multiple")
    }
}
