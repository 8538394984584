<loading-layout #loadingLayoutPaso1>
    <cui-section-headline>Paso 1: Ingresar instrumentos y generador.</cui-section-headline>
    <div class="info-container">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <input
                        class="form-control"
                        placeholder="Instrumento IDs (Ej: 1, 21, 34)"
                        type="text"
                        ngModel
                        (ngModelChange)="onChangeInstrumentoIds($event)"
                    />
                </div>
                <cui-button
                    class="btn_style"
                    [disabled]="!selectedGeneradorInstrumento || !instrumentoIds"
                    (click)="getInstrumentos()"
                >
                    Buscar
                </cui-button>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <select id="curriculum" [(ngModel)]="selectedGeneradorInstrumento" class="form-control">
                        <option [ngValue]="undefined" [disabled]="true" [selected]="true">
                            -- Seleccione Tipo de Generador --
                        </option>
                        <option *ngFor="let generador of generadorInstrumentos" [ngValue]="generador">
                            {{ generador.generador_instrumento }}
                        </option>
                    </select>
                </div>
                <div *ngIf="selectedGeneradorInstrumento" class="generador-info">
                    <p>
                        <label>Tipo:</label>
                        {{ selectedGeneradorInstrumento?.tipo_instrumento.tipo_instrumento }}
                    </p>
                    <p>
                        <label>N° Preguntas:</label>
                        {{ selectedGeneradorInstrumento.numero_preguntas }}
                        <i>(Referencial)</i>
                    </p>
                    <p>
                        <label>Tiempo:</label>
                        {{ selectedGeneradorInstrumento.tiempo }} minutos
                    </p>
                </div>
                <p class="tutorial" *ngIf="!selectedGeneradorInstrumento">Seleccione un tipo de generador.</p>
            </div>
        </div>
    </div>
</loading-layout>

<loading-layout #loadingLayoutPaso2>
    <div *ngIf="instrumentos && selectedGeneradorInstrumento">
        <cui-section-headline>Paso 2: Verificar y crear.</cui-section-headline>
        <div class="info-container">
            <ng-container *ngIf="instrumentos.length > 0">
                <div class="cards">
                    <div class="card" *ngFor="let instrumento of instrumentos">
                        <div class="card-title">Instrumento #{{ instrumento.id }}</div>
                        <div class="card-body">
                            <div class="fields">
                                <div class="field">
                                    <div class="field-label">Generador Instrumento</div>
                                    <div class="field-type">
                                        {{
                                            instrumento.generador_instrumento?.generador_instrumento
                                                | easyplaceholder: "Sin Generador"
                                        }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Numero Preguntas</div>
                                    <div class="field-type">
                                        {{ instrumento.numero_preguntas }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Tiempo</div>
                                    <div class="field-type">
                                        {{ instrumento.tiempo }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Oficial</div>
                                    <div class="field-type">
                                        {{ instrumento.oficial ? "Si" : "No" }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Creador</div>
                                    <div class="field-type">
                                        {{ instrumento.usuario?.nombreCompleto() | easyplaceholder: "Sin Creador" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="crear-container">
                    <cui-button [disabled]="savingInstrumentoMultiple" class="btn_style" (click)="createMultiple()">
                        Crear
                    </cui-button>
                    <loader *ngIf="savingInstrumentoMultiple" loaderType="default-xsmall"></loader>
                </div>
                <p *ngIf="instrumentoMultiple">
                    El último instrumento múltiple guardado tiene el ID #{{ instrumentoMultiple.id }}
                </p>
            </ng-container>
            <ng-container *ngIf="instrumentos.length == 0">
                <p>No hay instrumentos con esos IDs</p>
            </ng-container>
        </div>
    </div>
</loading-layout>
