import { BaseForm } from "@puntaje/shared/core"

export class NivelForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "ID", type: "hidden", visible: false },
            nivel: { label: "Nivel", type: "text", visible: true },
            experiencia: { label: "Puntuación", type: "number", visible: true },
            url_imagen: { label: "Recompensa", type: "text", visible: true }
        }
    }
}
