import { TextView, LinkView, DateView } from "@puntaje/shared/core"
import { GrupoRecursoTipos } from "./grupo_recurso_tipos.service"

export class GrupoRecursoTipoView {
    public static get viewParams() {
        return {
            id: {
                label: "Id",
                type: TextView,
                tableVisible: true
            },
            grupo_recurso_tipo: {
                label: "Categoria / Grupo Recurso",
                type: TextView,
                tableVisible: true
            },
            orden: { label: "Orden", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
