import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-lista-asignatura-evaluacion-tipos",
    templateUrl: "./lista-asignatura-evaluacion-tipos.component.html",
    styleUrls: ["./lista-asignatura-evaluacion-tipos.component.scss"]
})
export class ListaAsignaturaEvaluacionTiposComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.asignatura_evaluacion_tipos.list")
    }
}
