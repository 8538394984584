<loading-layout>
    <div *ngIf="!loadingLayout.loading">
        <div class="establecimiento-data container-fluid print-hide">
            <ng-container *ngFor="let key of params | keys">
                <model-simple-view
                    *ngIf="!params[key]['showHidden']"
                    [params]="params[key]"
                    [value]="grupoRecursoTipo"
                    [key]="key"
                ></model-simple-view>
            </ng-container>
        </div>

        <h2>{{ grupoRecursoTipo?.grupo_recurso_tipo_categoria?.grupo_recurso_tipo_categoria }}</h2>
        <div class="recursos-container">
            <div class="row">
                <div class="col-md-6">
                    <div class="agregar-container">
                        <div class="form-group">
                            <label>Agregar Recurso (por ID)</label>
                            <input class="form-control" type="number" [(ngModel)]="recursoId" />
                        </div>
                        <div *ngIf="mensajeError" class="alert alert-danger">
                            {{ mensajeError }}
                        </div>
                        <cui-button [disabled]="btnDisabled" class="btn_style" (click)="agregar()">
                            Agregar Recurso
                        </cui-button>
                    </div>

                    <h2 class="subtitle">Recursos asociados (puedes arrastrarlos para cambiar el orden)</h2>
                    <ng-template #itemTemplate let-item="item" let-index="index">
                        <div class="recurso">
                            <div class="recurso-text">
                                {{ item.value.orden }}. ID: {{ item.value.recurso_id }}.
                                {{ item.value.recurso.nombre }}
                            </div>

                            <a class="recurso-eliminar" href="javascript:void(0)" (click)="eliminar(item.value)">
                                <span class="glyphicon glyphicon-remove"></span>
                            </a>
                        </div>
                    </ng-template>
                    <div class="recursos-list">
                        <bs-sortable
                            [(ngModel)]="grupoRecursoTipo.grupo_recursos"
                            [itemTemplate]="itemTemplate"
                            (onChange)="setOrder($event)"
                        ></bs-sortable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
