<loading-layout #loadingLayoutPaso1>
    <cui-section-headline>Paso 1: Ingresar evaluaciones y tipo.</cui-section-headline>
    <div class="info-container">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <input
                        class="form-control"
                        placeholder="Nombre"
                        type="text"
                        [(ngModel)]="evaluacionMultipleNombre"
                    />
                </div>
                <div class="form-group">
                    <input
                        class="form-control"
                        placeholder="Evaluacion IDs (Ej: 1, 21, 34)"
                        type="text"
                        ngModel
                        (ngModelChange)="onChangeEvaluacionIds($event)"
                    />
                </div>

                <cui-button
                    class="btn_style"
                    [disabled]="
                        !selectedEvaluacionTipo ||
                        !evaluacionIds ||
                        !evaluacionMultipleNombre ||
                        !selectedGeneradorInstrumento
                    "
                    (click)="getEvaluaciones()"
                >
                    Buscar
                </cui-button>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <select id="curriculum" [(ngModel)]="selectedGeneradorInstrumento" class="form-control">
                        <option [ngValue]="undefined" [disabled]="true" [selected]="true">
                            -- Seleccione Tipo de Generador --
                        </option>
                        <option
                            *ngFor="let generadorInstrumento of generadorInstrumentos"
                            [ngValue]="generadorInstrumento"
                        >
                            {{ generadorInstrumento.generador_instrumento }}
                        </option>
                    </select>
                </div>
                <p class="tutorial" *ngIf="!selectedGeneradorInstrumento">Seleccione un tipo de generador.</p>
                <div class="form-group">
                    <select id="curriculum" [(ngModel)]="selectedEvaluacionTipo" class="form-control">
                        <option [ngValue]="undefined" [disabled]="true" [selected]="true">
                            -- Seleccione Tipo de Evaluación --
                        </option>
                        <option *ngFor="let evaluacionTipo of evaluacionTipos" [ngValue]="evaluacionTipo">
                            {{ evaluacionTipo.evaluacion_tipo }}
                        </option>
                    </select>
                </div>
                <p class="tutorial" *ngIf="!selectedEvaluacionTipo">Seleccione un tipo de evaluación.</p>
            </div>
        </div>
    </div>
</loading-layout>

<loading-layout #loadingLayoutPaso2>
    <div *ngIf="evaluaciones && selectedEvaluacionTipo">
        <cui-section-headline>Paso 2: Verificar y crear.</cui-section-headline>
        <div class="info-container">
            <ng-container *ngIf="evaluaciones.length > 0">
                <div class="cards">
                    <div class="card" *ngFor="let evaluacion of evaluaciones">
                        <div class="card-title">Evaluacion #{{ evaluacion.id }}</div>
                        <div class="card-body">
                            <div class="fields">
                                <div class="field">
                                    <div class="field-label">Nombre</div>
                                    <div class="field-type">
                                        {{ evaluacion.evaluacion ? evaluacion.evaluacion : "Sin nombre" }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Instrumento ID</div>
                                    <div class="field-type">
                                        {{ evaluacion.instrumento_id }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Evaluacion Tipo</div>
                                    <div class="field-type">
                                        {{
                                            evaluacion.evaluacion_tipo?.evaluacion_tipo
                                                | easyplaceholder: "Sin evaluación tipo"
                                        }}
                                    </div>
                                </div>

                                <div class="field">
                                    <div class="field-label">Fecha Inicio</div>
                                    <div class="field-type">
                                        {{ evaluacion.fecha_inicio | date: "dd-MM-yyyy" | easyplaceholder: "N/A" }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Fecha Termino</div>
                                    <div class="field-type">
                                        {{ evaluacion.fecha_termino | date: "dd-MM-yyyy" | easyplaceholder: "N/A" }}
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label">Fecha Mostrar Respuestas</div>
                                    <div class="field-type">
                                        {{
                                            evaluacion.fecha_mostrar_respuestas
                                                | date: "dd-MM-yyyy"
                                                | easyplaceholder: "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="crear-container">
                    <cui-button [disabled]="savingEvaluacionMultiple" class="btn_style" (click)="createMultiple()">
                        Crear
                    </cui-button>
                    <loader *ngIf="savingEvaluacionMultiple" loaderType="default-xsmall"></loader>
                </div>
                <p *ngIf="evaluacionMultiple">
                    La última evaluación múltiple guardada tiene el ID #{{ evaluacionMultiple.id }} con ID de
                    instrumento múltiple #{{ evaluacionMultiple.instrumento_multiple_id }}
                </p>
            </ng-container>
            <ng-container *ngIf="evaluaciones.length == 0">
                <p>No hay evaluaciones con esos IDs</p>
            </ng-container>
        </div>
    </div>
</loading-layout>
