import { Component, OnInit, Input, ViewChild } from "@angular/core"
import {
    GrupoRecursoTipoView,
    GrupoRecursoTipos,
    GrupoRecursoTipo,
    GrupoRecursos,
    GrupoRecurso
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "grupo-recurso",
    templateUrl: "./grupo-recurso.component.html",
    styleUrls: ["./grupo-recurso.component.scss"]
})
export class GrupoRecursoComponent implements OnInit {
    @Input() grupoRecursoTipoId: number

    recursoTipo: string = "InstrumentoPredefinido"
    recursoId: number
    mensajeError: string

    grupoRecursoTipo: any

    btnDisabled: boolean = false

    timer: any
    skipSetOrder: boolean = false

    params = GrupoRecursoTipoView.viewParams
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected grupoRecursoTiposService: GrupoRecursoTipos, protected grupoRecursosService: GrupoRecursos) {}

    ngOnInit() {
        this.getGrupoRecursoTipo()
    }

    getGrupoRecursoTipo() {
        this.loadingLayout.standby()

        this.grupoRecursoTiposService.enableIgnoreModel()
        this.grupoRecursoTiposService
            .find(this.grupoRecursoTipoId, { include: "[grupo_recurso_tipo_categoria,grupo_recursos:recurso]" })
            .then((grupoRecursoTipo: any) => {
                this.grupoRecursoTiposService.disableIgnoreModel()

                this.recursoTipo = grupoRecursoTipo.grupo_recurso_tipo_categoria.recurso_type
                this.grupoRecursoTipo = grupoRecursoTipo

                this.loadingLayout.ready()
            })
    }

    setOrder(grupoRecursos: any[]) {
        if (grupoRecursos.length == 0) return

        grupoRecursos.forEach((gr, index) => {
            gr.orden = index
        })

        if (this.skipSetOrder) {
            this.skipSetOrder = false

            return
        }

        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = 0
        }

        this.timer = setTimeout(() => {
            this.saveOrder()
        }, 1000)
    }

    saveOrder() {
        const grupoRecursoTipo = new GrupoRecursoTipo()
        grupoRecursoTipo.id = this.grupoRecursoTipoId

        grupoRecursoTipo.grupo_recursos = this.grupoRecursoTipo.grupo_recursos.map(gr => {
            const grupoRecurso = new GrupoRecurso()
            grupoRecurso.id = gr.id
            grupoRecurso.orden = gr.orden

            return grupoRecurso
        })

        this.grupoRecursoTiposService.update(grupoRecursoTipo.id, grupoRecursoTipo).then(gr => {})
    }

    eliminar(grupoRecurso) {
        this.grupoRecursosService.remove(grupoRecurso.id).then(() => {
            this.getGrupoRecursoTipo()
        })
    }

    agregar() {
        this.btnDisabled = true

        this.mensajeError = ""

        const exists = this.grupoRecursoTipo.grupo_recursos.some(
            gr => gr.recurso_type == this.recursoTipo && gr.recurso_id == this.recursoId
        )
        if (exists) {
            this.mensajeError = "El Instrumento predefinido ya existe."
            this.btnDisabled = false

            return
        }

        const grupoRecurso = new GrupoRecurso()

        grupoRecurso.recurso_type = this.recursoTipo
        grupoRecurso.recurso_id = this.recursoId
        grupoRecurso.orden = this.grupoRecursoTipo.grupo_recursos.length
        grupoRecurso.grupo_recurso_tipo_id = this.grupoRecursoTipo.id

        this.grupoRecursosService.enableIgnoreCatch()
        this.grupoRecursosService
            .save(grupoRecurso)
            .then(grupoRecurso => {
                this.grupoRecursosService.disableIgnoreCatch()
                this.getGrupoRecursoTipo()
                this.skipSetOrder = true

                this.btnDisabled = false
            })
            .catch(e => {
                this.mensajeError = `El ${this.recursoTipo || 'recurso'} no existe.`
                this.btnDisabled = false
            })
    }
}
