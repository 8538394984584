import { PuntajeEscalaGlobalesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { escalaGlobalesRouting } from "./admin-escala-globales.routing"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { AdminLayoutsModule } from "../layouts/admin_layouts.module"
import { EscalaGlobalesModule } from "@puntaje/puntaje/new-modules/escala-globales"

@NgModule({
    declarations: [],
    imports: [
        escalaGlobalesRouting,
        CommonModule,
        CommonsLayoutsModule,
        AdminLayoutsModule,
        EscalaGlobalesModule,
        PuntajeEscalaGlobalesModule
    ]
})
export class AdminEscalaGlobalesModule {}
