import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./escala-globales-new.component.html",
    styleUrls: ["./escala-globales-new.component.scss"]
})
export class EscalaGlobalesNewComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.escala_globales.new")
    }
}
