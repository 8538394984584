import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./horarios-new.component.html",
    styleUrls: ["./horarios-new.component.scss"]
})
export class HorariosNewComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit(): void {}
}
