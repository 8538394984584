import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./niveles.component.html",
    styleUrls: ["./niveles.component.scss"]
})
export class NivelesComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.niveles.list")
    }
}
