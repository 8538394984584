import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./nivel-edit.component.html",
    styleUrls: ["./nivel-edit.component.scss"]
})
export class NivelEditComponent implements OnInit {
    sub: Subscription
    nivelId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.niveles.edit")
        this.sub = this.route.params.subscribe(params => {
            this.nivelId = +params["id"]
        })
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }
}
