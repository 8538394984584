import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./grupo-recursos-edit.component.html",
    styleUrls: ["./grupo-recursos-edit.component.scss"]
})
export class GrupoRecursosEditComponent implements OnInit {
    grupoRecursoTipoCategorias: string[]

    subQuery: Subscription
    protected sub: Subscription
    grupoRecursoTipoId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.grupo_recursos.new")
        this.sub = this.route.params.subscribe(params => {
            this.grupoRecursoTipoId = +params["id"]
        })

        this.subQuery = this.route.queryParams.subscribe(queryParams => {
            this.grupoRecursoTipoCategorias =
                queryParams["grupo_recurso_tipo_categorias"] || this.grupoRecursoTipoCategorias
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
    }
}
