import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-asignatura-evaluacion-tipos-edit",
    templateUrl: "./asignatura-evaluacion-tipos-edit.component.html",
    styleUrls: ["./asignatura-evaluacion-tipos-edit.component.scss"]
})
export class AsignaturaEvaluacionTiposEditComponent implements OnInit {
    asignaturaEvaluacionTipoId: number
    protected sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.asignatura_evaluacion_tipos.edit")
        this.sub = this.route.params.subscribe(params => {
            this.asignaturaEvaluacionTipoId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
