import {
    ListaAsignaturaEvaluacionTiposComponent,
    AsignaturaEvaluacionTiposNewComponent,
    AsignaturaEvaluacionTiposEditComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const asignaturaEvaluacionTiposRoutes: Routes = [
    {
        path: "asignatura_evaluacion_tipos",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: ListaAsignaturaEvaluacionTiposComponent, canActivate: [TokenGuard, AuthGuard] }
        ]
    },
    {
        path: "asignatura_evaluacion_tipos/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsignaturaEvaluacionTiposNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asignatura_evaluacion_tipos/:id/edit",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: AsignaturaEvaluacionTiposEditComponent, canActivate: [TokenGuard, AuthGuard] }
        ]
    }
]

export const asignaturaEvaluacionTiposRouting = RouterModule.forChild(asignaturaEvaluacionTiposRoutes)
