import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { EscalaGlobalesModule } from "@puntaje/puntaje/new-modules/escala-globales"
import { EscalaGlobalesNewComponent } from "./escala-globales-new/escala-globales-new.component"
import { EscalaGlobalesEditComponent } from "./escala-globales-edit/escala-globales-edit.component"
import { EscalaGlobalesListComponent } from "./escala-globales-list/escala-globales-list.component"
import { EscalaGlobalesViewComponent } from "./escala-globales-view/escala-globales-view.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [
        EscalaGlobalesNewComponent,
        EscalaGlobalesEditComponent,
        EscalaGlobalesListComponent,
        EscalaGlobalesViewComponent
    ],
    exports: [
        EscalaGlobalesNewComponent,
        EscalaGlobalesEditComponent,
        EscalaGlobalesListComponent,
        EscalaGlobalesViewComponent
    ],
    imports: [CommonModule, CommonsLayoutsModule, EscalaGlobalesModule, CUILayoutsModule]
})
export class PuntajeEscalaGlobalesModule {}
